import { Resume } from "../models/resume";

const Resuming = ({ skillmessage, education, works, skills }: Resume) => {
  return <>
    <h3 className="mx-20 text-2xl px-20 py-5">Work Experience</h3>
    <div className="bg-white grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 my-10 mx-80">
      {works?.map((work, index) => {
        return (
          <div
            key={index}
            className="w-auto h-auto rounded overflow-hidden shadow-lg mx-2 my-2"
          >
            <img
              className="w-full h-96"
              src={work.image}
              alt="Sunset in the mountains"
            />
            <div className="px-6 py-4 text-white">
              <div className="font-bold text-xl mb-2 text-black">
                <span className="flex justify-start font-semibold">
                    {work.company}
                </span>
                <span className="flex justify-end py-1 text-lg font-semibold">
                  {work.title}
                </span>
              </div>
              <p className="text-gray-500 font">
                {work.achievements.map((achievement, index) => {
                  return (
                    <>
                      <span className="font-bold">o</span> {achievement}
                      <br />
                    </>
                  );
                })}
              </p>
            </div>
            <div className="px-6 pt-4 pb-2 flex justify-between">
              <span className="justify-content-start inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                <a className="text-gray-500" href={work.url}>
                <i className="w-6 h-6" /> Web site
                </a>
              </span>
              <span className="justify-end start-end inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                {work.years}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  </>;
};

export default Resuming;
