import React from "react";
//import ContactDirectly from './ContactDirectly';

const About = ({ data }) => {
  if (data) {
    var bio = data.bio;
    //var resumeDownload = data.resumedownload;
  }

  return (
    <section id="about">
      <div className="row">
        <div className="three columns">
           <h2>About Me</h2>
        </div>
        <div className="nine columns main-col">
          <p className="text-white">{bio}</p>
          <div className="row">
           <div className="col-4">
           <div className="columns download">
              <p>
                <a
                  href="mailto:lawal.hammid@gmail.com?subject=I need your CV for a client"
                  target="_blank"
                  className="button"
                  rel="noreferrer"
                >
                  <i className="fa fa-envelope"></i>Request Resume
                </a>
              </p>
            </div>
           </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;