import axios  from "axios";
import { publicBaseUrl } from './publicBaseUrl';

export default axios.create({
    baseURL: publicBaseUrl(), 
    timeout:  1000 * 20,
    //withCredentials: true,
    headers: {
      Accept: "applicaiton/json",
      "Content-Type": "application/json",
    },
});