import api  from './AxiosConfiguration';
import { HandlesTimeOut } from './HandlesTimeOut';

export const  PostAPIResources = async (url, postData, callBack) => {
    try
    {
        const response = await api.post(url, postData);
        callBack(response.data);
    }
    catch(error) {
        if(HandlesTimeOut(error)){
            const response = {
                response: { success: false,
                    responseMessage: "Looks like the server is taking to long to respond, please try again in sometime"},
            }

            callBack(response);
        }
        else { 
            const responseError = {
                response: error.response.data,
            }
            callBack(responseError);
        }
    }
}