import { useEffect, useState } from "react";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import About from "./Components/About";
import Contact from "./Components/HireMe";
import Testimonials from "./Components/Testimonials";
import Portfolio from "./Components/Portfolio";
import "./App.css";
import { Profile } from "./models/profile";
import Resuming from "./Components/Resuming";

const App = () => {

  const [resumeData, setResumeData] = useState<Profile>();

  useEffect(() => {
    fetch("/resumeData.json")
      .then((res) => res.json())
      .then((data: Profile) => {
        console.log('useEffect: ', data)
        setResumeData(data);
      });
  }, []);

  
  
  return (
    <div className="App">
      <Header data={resumeData?.main} />
      <About data={resumeData?.main} />
      <Resuming skillmessage = {resumeData?.resume.skillmessage}
                 education = {resumeData?.resume?.education} 
                 works  = {resumeData?.resume?.works}
                 skills ={resumeData?.resume.skills} />
      <Portfolio data={resumeData?.portfolio} />
      <Testimonials data={resumeData?.testimonials} />
      <Contact data={resumeData?.main} />
      <Footer data={resumeData?.main} />
      
    </div>
  );
};

export default App;
