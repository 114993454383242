interface ContactDirectlyParam {
  phone: string;
  phoneToDial: string
  email: string;
  whatsapp: string
}

const ContactDirectly = ({ phone, phoneToDial, email, whatsapp }: ContactDirectlyParam) => {
  console.log('ContactDirectly', email)
  return (
   
      <div className="">
      <aside className="four columns footer-widgets">
            <h4 style={{color: "white"}}>Contact me directly</h4>
            <p className="address">
              Email:{" "}
              <a
                href={`mailto:${email}`}
                style={{ textDecoration: "underline" }}
              >
                {email}
              </a>
              <br />
              <span>Phone number: </span>
              <span style={{color: "white"}} 
                
              >
               {phone} or
              </span> 
              <span>   
                    <a
                      href={`tel:${phoneToDial}`}
                      style={{ textDecoration: "underline" }}
                  >
                      Click here to dial
                  </a> 
              </span>
              <br></br>
              <span>WhatsApp: </span>  
              <a
                href={`https://wa.me/${whatsapp}`}
                style={{ textDecoration: "underline" }}
              >
                Click here to chat
              </a>
              <br />
            </p>
       
        </aside>
      </div>
     

  );
};

export default ContactDirectly;
