import React, { useState, useEffect } from "react";
import ContactDirectly from "./ContactDirectly";
import { PostAPIResources } from "../APIResources/PostAPIResources";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { ValidateEmail } from "./../Helpers/ValidateEmail";

const Contact = ({ data }) => {
  console.log('Contact: ', data);
  const [submitInProgress, setsubmitInProgress] = useState(false);
  const [apiResponse, setapiResponse] = useState({});

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: { userType: "Contract/Hire" },
  });

  let emailWatch = watch("emailAddress", false);
  if (emailWatch !== "") {
    const validateEmail = ValidateEmail(emailWatch);
    if (!validateEmail.success) {
      emailWatch = validateEmail.responseMessage;
    } else {
      emailWatch = "";
    }
  }

  useEffect(() => {}, []);

  const submitForm = async (data) => {
    setsubmitInProgress(true);
    setapiResponse({});
    try 
    {
          await PostAPIResources("LO/v1/Users", data, (response) => {
            setapiResponse(response.response);
          });

      setsubmitInProgress(false);
      reset();
    } catch (error) {
      setsubmitInProgress(false);
      setapiResponse(error);
    }
  };

  return (
    <section id="contact">
      <div className="row section-head">
        <div className="two columns header-col">
          <h1>
            <span>Contact</span>
          </h1>
        </div>

        <div className="ten columns">
          <p className="lead">Get in touch with Me</p>
        </div>
      </div>
      <div className="row">
        <div className="eight columns">
          <form onSubmit={handleSubmit(submitForm)}>
            <fieldset>
              <label>
                Name{" "}
                <span className="required">
                  <ErrorMessage errors={errors} name="fullName" />
                  <ErrorMessage
                    errors={errors}
                    name="fullName"
                    render={({ message }) => <p></p>}
                  />
                </span>
              </label>
              <input
                {...register("fullName", {
                  required: "*",
                })}
              />

              <label>
                Email{" "}
                <span className="required">
                  <ErrorMessage errors={errors} name="emailAddress" />
                  <ErrorMessage
                    errors={errors}
                    name="emailAddress"
                    render={({ message }) => <p></p>}
                  />
                </span>
              </label>
              <input
                {...register("emailAddress", {
                  required: "* Invalid email",
                  message: "You must enter a valid email address",
                  pattern: /^\S+@\S+$/i,
                })}
              />

              <label>
                Message{" "}
                <span className="required">
                  <ErrorMessage errors={errors} name="projectDescription" />
                  <ErrorMessage
                    errors={errors}
                    name="projectDescription"
                    render={({ message }) => <p></p>}
                  />
                </span>
              </label>
              <textarea
                cols="50"
                rows="10"
                placeholder="Enter project/contract summary"
                {...register("projectDescription", {
                  required: "*",
                })}
              ></textarea>

              <button
                disabled={submitInProgress}
                type="submit"
                className="submit"
              >
                {submitInProgress === false ? "Submit" : "..."}
              </button>
            </fieldset>
            <div>
              <span className="required">
                {emailWatch}
                {apiResponse.success === true ? (
                  <span className="spanSuccess">
                    <i className="fa fa-check"></i>{" "}
                    {apiResponse.responseMessage}
                  </span>
                ) : (
                  <span className="spanError">
                    {apiResponse.responseMessage}
                  </span>
                )}
              </span>
              <br />
            </div>
          </form>
        </div>
        <ContactDirectly phone={data?.phone} email={data?.email}/>
      </div>
    </section>
  );
};

export default Contact;
