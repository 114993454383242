interface ResponseValEmail {
  success: boolean;
  responseMessage: string;
}
export const ValidateEmail = (email: boolean): ResponseValEmail => {
  if(typeof(email) === 'boolean'){
    return  {
      success: true,
      responseMessage: "You have entered an invalid email address!"
    } ;
  }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      return {
        success: false,
        responseMessage: "Invalid email address/empty space"
      } 
    }
    return  {
        success: true,
        responseMessage: "You have entered an invalid email address!"
      } ;
  };