import React, { useState } from "react";
import { PostAPIResources } from "../APIResources/PostAPIResources";
import { useForm } from "react-hook-form";

const Header = ({ data }) => {
  const [changeViewForFreeTrainig, setchangeViewForFreeTrainig] =
    useState(false);
  const [submitInProgress, setsubmitInProgress] = useState(false);
  const [apiResponse, setapiResponse] = useState({});
  const {
    register,
    handleSubmit,
    //watch,
    formState: { errors, isDirty, isValid, dirtyFields },
    reset,
    // getValues
  } = useForm({
    mode: "onChange",
    defaultValues: {
      userType: "free-learning/project",
      emailAddress: "",
    },
  });

  const registerFreeTutorial = () => {
    setchangeViewForFreeTrainig(true);
  };

  const submitForm = async (data) => {
    setsubmitInProgress(true);
    setapiResponse({});
    try {
      await PostAPIResources("LO/v1/Users", data, (response) => {
        setapiResponse(response.response);
        if (response.response.success) {
          setsubmitInProgress(false);
          setchangeViewForFreeTrainig(false);
          reset();
        }
        setsubmitInProgress(false);
      });
    } catch (error) {
      setsubmitInProgress(false);
      setapiResponse(error);
    }
  };

  if (data) {
    var name = data.name;
    var profilepic = "./images/" + data.image;
    var networks = data.social.map(function (network) {
      return (
        <li key={network.name}>
          <a
            title={network.title}
            href={network.url}
            target="_blank"
            rel="noreferrer"
          >
            <i className={network.className}></i>
          </a>
        </li>
      );
    });
  }
  return (
    <>
      <header id="home">
        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a className="smoothscroll" href="#home">
                Home
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#about">
                About
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#resume">
                Resume
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#portfolio">
                Projects
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#testimonials">
                Testimonials
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#contact">
                Hire me
              </a>
            </li>
          </ul>
        </nav>
        <div className="row banner">
          <div className="banner-text">
            <div className="three columns">
              <img
                className="profile-pic"
                src={profilepic}
                alt="lawal's Profile Pic"
                style={{
                  borderRadius: "100%",
                  width: "120px",
                  height: "120px",
                }}
              />
            </div>
            <h1 className="responsive-headline">
              {name ? `${"Hello,"} I'm ${name}.` : null}
            </h1>

            <hr />

            <ul className="social">{networks}</ul>

            <br></br>
            {changeViewForFreeTrainig === true ? (
              <>
                <form id="formSubmitForm" onSubmit={handleSubmit(submitForm)}>
                  <input
                    size="35"
                    placeholder="Enter email address"
                    {...register("emailAddress", {
                      required: "Please enter your email address",
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Invalid email address",
                      },
                    })}
                    id="emailAddress"
                    name="emailAddress"
                    type="email"
                    autoComplete="off"
                    className={`freeLearningInput ${
                      !errors.emailAddress &&
                      dirtyFields.emailAddress &&
                      "!bg-green-50"
                    }`}
                  />

                  <button
                    disabled={submitInProgress || !isDirty || !isValid}
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded cursor-pointer w-40 h-24"
                  >
                    {submitInProgress === false ? "Submit" : "..."}
                  </button>
                
                  <br></br>

                  <span className="spanWhiteText">
                    <span className="spanWhiteText">
                      {errors.emailAddress?.message}
                    </span>
                    {apiResponse.success === false ? (
                      <span className="spanError">
                        {apiResponse.responseMessage}
                      </span>
                    ) : (
                      <></>
                    )}
                  </span>
                </form>
              </>
            ) : (
              <>
                <button onClick={registerFreeTutorial} class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded h-24">
                  Get Free Learning/Project
                </button>

                <br></br>
                {apiResponse.success === true ? (
                  <span className="spanWhiteText">
                    <span className="spanSuccess">
                      <i className="fa fa-check"></i>{" "}
                      {apiResponse.responseMessage}
                    </span>
                  </span>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>

        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    </>
  );
};

export default Header;
